import themeJson from "@/assets/theme/theme.json";

const Config = {
  state: {
    BASEURL: "http://vpn.ygddzy.cn:16038",
    themeColor: sessionStorage.getItem("bgColor")
      ? sessionStorage.getItem("bgColor")
      : "",
    topMenu: [],
    topActiveToken: "",
    leftMenu: [],
    leftActiveToken: "",
    leftDefaultMenu: [],
    rightMenu: [],
    rightActiveToken: "",
    rightBtn: [],
    /**
     *设置主题色
     */
    activeTheme: sessionStorage.getItem("activeTheme")
      ? sessionStorage.getItem("activeTheme")
      : "", //选中的皮肤
    userInfo: {},
    isToggleAside: false,
  },
  mutations: {
    /**
     * 设置主题色
     */
    setActiveTheme(state, data) {
      state.activeTheme = data;
      sessionStorage.setItem("activeTheme", data);
      for (let key in themeJson) {
        let theme = themeJson[key];
        if (state.activeTheme === key) {
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--theme-color", theme.themeColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--warning-color", theme.warningColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--error-color", theme.errorColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty(
              "--theme-linear-color-left",
              theme.themeLinearColorLeft
            );
          document
            .getElementsByTagName("body")[0]
            .style.setProperty(
              "--theme-linear-color-right",
              theme.themeLinearColorRight
            );

          document
            .getElementsByTagName("body")[0]
            .style.setProperty(
              "--black-linear-color-left",
              theme.blackLinearColorLeft
            );
          document
            .getElementsByTagName("body")[0]
            .style.setProperty(
              "--black-linear-color-right",
              theme.blackLinearColorRight
            );
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--menu-bg-color", theme.menuBgColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--search-bg-color", theme.searchBgColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty(
              "--search-uncheck-bg-color",
              theme.searchUncheckBgColor
            );
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--theme-bg-color", theme.themeBgColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--text-color", theme.textColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--text-second-color", theme.textSecondColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--text-third-color", theme.textThirdColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--text-gray-color", theme.textGrayColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--text-white-color", theme.textWhiteColor);
          document
            .getElementsByTagName("body")[0]
            .style.setProperty("--icon-check-color", theme.iconCheckColor);
        }
      }
    },
    setTopMenu(state, data) {
      state.topMenu = data;
    },
    setTopActiveToken(state, data) {
      state.topActiveToken = data;
    },
    setLeftMenu(state, data) {
      state.leftMenu = data;
    },
    setLeftActiveToken(state, data) {
      state.leftActiveToken = data;
    },
    setLeftDefaultMenu(state, data) {
      state.leftDefaultMenu = data;
    },
    setRightMenu(state, data) {
      state.rightMenu = data;
    },
    setRightActiveToken(state, data) {
      state.rightActiveToken = data;
    },
    setRightBtn(state, data) {
      state.rightBtn = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setToggleAside(state, data) {
      state.isToggleAside = data;
    },
  },
  actions: {},
  getters: {},
};

export default Config;
