import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import saasConfig from "./config/config.js";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          saasConfig: val.saasConfig,
        };
      },
    }),
  ],
  modules: {
    saasConfig: saasConfig,
  },
});
