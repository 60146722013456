const common = [
  // 组织人员-组织与成员
  {
    path: "/organizer",
    name: "organizer",
    component: () => import("@/views/module/common/organizer/index.vue"),
  },
  // 组织人员-项目管理
  {
    path: "/organizer/project",
    name: "project",
    component: () => import("@/views/module/common/organizer/project.vue"),
  },
  // 组织人员-角色管理
  {
    path: "/organizer/role",
    name: "role",
    component: () => import("@/views/module/common/organizer/role.vue"),
  },
  // 推送管理
  {
    path: "/push",
    name: "push",
    component: () => import("@/views/module/common/push/index.vue"),
  },
  // 收款账户
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/module/common/account/index.vue"),
  },
  // 短信设置
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/module/common/message/index.vue"),
  },
];
export default common;
