import { createRouter, createWebHashHistory } from "vue-router";
import nonMotorVehicles from "./module/nonMotorVehicles.js"; //非机动车路由文件
import common from "./module/common.js"; //

import cookie from "../utils/cookie";

const routes = [
  {
    path: "",
    name: "",
    component: () => import("@/views/layout/index.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/auth",
        name: "auth",
        component: () => import("@/views/result/auth.vue"),
      },
      ...nonMotorVehicles,
      ...common,
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const isLogin = cookie.getCookie("token");
  if (!isLogin && to.path !== "/login") {
    next({
      path: "/login",
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
