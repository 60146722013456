<template>
  <router-view></router-view>
</template>
<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
	
	.flex-center{
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
