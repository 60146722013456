const nonMotorVehicles = [
  // 运营管理-通行记录
  {
    path: "/operations",
    name: "Operations",
    component: () =>
      import("@/views/module/nonMotorVehicles/operations/index.vue"),
  },
  // 运营管理-临停收费记录
  {
    path: "/operations/temporary",
    name: "TemporaryRecord",
    component: () =>
      import("@/views/module/nonMotorVehicles/operations/temporaryRecord.vue"),
  },
  // 运营管理-车辆列表
  {
    path: "/operations/car",
    name: "Car",
    component: () =>
      import("@/views/module/nonMotorVehicles/operations/car.vue"),
  },
  // 运营管理-特定车辆列表
  {
    path: "/operations/specific-car",
    name: "SpecificCar",
    component: () =>
      import("@/views/module/nonMotorVehicles/operations/specificCar.vue"),
  },
  // 车场管理
  {
    path: "/parking",
    name: "Parking",
    component: () =>
      import("@/views/module/nonMotorVehicles/parking/index.vue"),
  },
  // 新增车场
  {
    path: "/add-parking",
    name: "AddParking",
    component: () =>
      import("@/views/module/nonMotorVehicles/parking/page/addParking.vue"),
  },
  // 车场详情
  {
    path: "/parking-detail",
    name: "ParkingDetail",
    component: () =>
      import("@/views/module/nonMotorVehicles/parking/page/parkingDetail.vue"),
  },
  // 车场管理-闸机管理
  {
    path: "/parking/gate-machine",
    name: "gateMachine",
    component: () =>
      import("@/views/module/nonMotorVehicles/parking/gateMachine.vue"),
  },
  // 车场管理-ic卡
  {
    path: "/parking/card",
    name: "CardAdmin",
    component: () =>
      import("@/views/module/nonMotorVehicles/parking/cardAdmin.vue"),
  },
  // 车场管理-ic卡台账
  {
    path: "/parking/card-bill",
    name: "CardBill",
    component: () =>
      import("@/views/module/nonMotorVehicles/parking/cardBill.vue"),
  },
  // 车场管理-收费模板
  {
    path: "/parking/charge",
    name: "Charge",
    component: () =>
      import("@/views/module/nonMotorVehicles/parking/charge.vue"),
  },
  // 财务数据-订单管理
  {
    path: "/financing",
    name: "Financing",
    component: () =>
      import("@/views/module/nonMotorVehicles/financing/index.vue"),
  },
  // 财务数据-报表
  {
    path: "/financing/statement",
    name: "Statement",
    component: () =>
      import("@/views/module/nonMotorVehicles/financing/statement.vue"),
  },
  // 营销活动
  {
    path: "/activity",
    name: "Activity",
    component: () =>
      import("@/views/module/nonMotorVehicles/activity/index.vue"),
  },
  // 电子发票
  {
    path: "/invoice",
    name: "Invoice",
    component: () =>
      import("@/views/module/nonMotorVehicles/invoice/index.vue"),
  },
  // 系统设置 - 参数配置
  {
    path: "/setting",
    name: "Setting",
    component: () =>
      import("@/views/module/nonMotorVehicles/setting/index.vue"),
  },
  // 系统设置 - 收款账户配置
  {
    path: "/setting/collection",
    name: "Collection",
    component: () =>
      import("@/views/module/nonMotorVehicles/setting/collection.vue"),
  },
  // 系统设置 - 授权信息配置
  {
    path: "/setting/auth",
    name: "Auth",
    component: () => import("@/views/module/nonMotorVehicles/setting/auth.vue"),
  },
];
export default nonMotorVehicles;
