import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from '@/plugins/element'
// import 'default-passive-events'
const app = createApp(App)

/**
 * 缓存的主题色
 */
store.commit('setActiveTheme', sessionStorage.getItem('activeTheme') ? sessionStorage.getItem('activeTheme') : 'blue')


import '@/assets/theme/theme.scss';
import '@/assets/style/reset.css';
import '@/assets/alibaba/iconfont.css';
import '@/assets/style/index.scss';


installElementPlus(app)
app.use(store)
app.use(router)
app.mount('#app')
