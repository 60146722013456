export default {
  setCookie: (name, value, days) => {
    const d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
    window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
  },

  getCookie: name => {
    const v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
    return v ? v[2] : null
  },

  delCookie: name => {
    this.setCookie(name, '', -1)
  }
}
